export const fetchPlayerStats = async (playerId = 8479318, year = 20192020) => {
  const PLAYER_DATA_URL = `https://statsapi.web.nhl.com/api/v1/people/${playerId}?expand=person.stats&stats=statsSingleSeason&season=${year}&expand=stats.team&site=en_nhlCA`

  const response = await fetch(PLAYER_DATA_URL)

  if (response.status === 200) {
    const data = await response.json()
    const birthDate = data.people[0].birthDate
    const height = data.people[0].height
    const playerName = data.people[0].fullName
    const parsedData = data.people[0].stats[0].splits[0].stat
    const weight = data.people[0].weight

    return {
      name: playerName,
      stats: parsedData,
      birthDate: birthDate,
      height: height,
      weight: weight,
    }
  }
}

export const fetchTeamRoster = async (id = 10, year = 20192020) => {
  const DATA_URL = `https://statsapi.web.nhl.com/api/v1/teams/${id}?expand=team.roster&season=${year}`
  const response = await fetch(DATA_URL)

  if (response.status === 200) {
    const data = await response.json()

    const rosterArray = data.teams[0].roster.roster
    return rosterArray.map(item => {
      return {
        id: item.person.id,
        name: item.person.fullName,
        position: item.position.abbreviation,
      }
    })
  }
}
