import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

export const pagesData = [
  {
    title: 'Covid 19 Datasets for each Province in Canada',
    url: '/news/covid-19',
    subject: 'News',
    type: 'discussion'
  },
  {
    title: 'Shelter Occupancy vs Capacity - Toronto, ON',
    url: '/social-justice/homeless-shelter-occupancy/sample',
    subject: 'Social Justice',
    type: 'discussion'
  },
  {
    title: "NHL Player of the Week Investigation",
    url: "/hockey/player-of-the-week-questions/sample",
    subject: "Sports",
    type: "discussion",
  },
  {
    title: "Spotify Track Danceability",
    url: "/music/song-danceability",
    subject: "Music",
    type: "discussion",
  },
  // {
  //   title: 'Hockey player question generator',
  //   url: '/hockey/player-questions/sample',
  //   subject: 'Tools',
  //   type: 'tool'
  // }
]

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <h1>Math discussions using relevant and current data.</h1>
      <br />
      <br />
      <br />
      <br />
      <br />
      {pagesData.map(item => (
        <div style={{ marginBottom: 40 }}>
          <h4>{item.subject}</h4>
          <Link to={item.url}>{item.title}</Link>
        </div>
      ))}
    </Layout>
  )
}

export default IndexPage
