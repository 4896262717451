import React, { useEffect, useState } from "react"
import { teams } from "../../../api/hockeyTeams"
import {
  fetchPlayerStats,
  fetchTeamRoster,
} from "../../../api/hockeyPlayerQuestions"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import { pagesData } from "../../index"

const HockeyPlayerSampleQuestions = () => {
  const [team, setTeam] = useState(null)
  const [roster, setRoster] = useState([])
  const [player, setPlayer] = useState(null)
  const [question, setQuestion] = useState()
  const [triggerQuestion, setTriggerQuestion] = useState(0)
  const [inputQuestion, setInputQuestion] = useState(
    "${player.name} has ${player.stats.assists} assists and ${player.stats.points} points. How many goals does he have?"
  )

  const playerQuestion = id =>
    fetchPlayerStats(id).then(player => {
      return {
        question: eval("`" + inputQuestion + "`"),
      }
    })

  useEffect(() => {
    if (team !== null) {
      fetchTeamRoster(team).then(res => setRoster(res))
    }
  }, [team])

  useEffect(() => {
    if (player !== null) {
      playerQuestion(player).then(res => {
        setQuestion(res)
      })
    }
  }, [triggerQuestion])

  return (
    <Layout>
      <label for="team-select">1. Choose a team.</label>
      <br />
      <select
        name="team"
        id="team-select"
        onChange={e => setTeam(e.target.value)}
      >
        <option value="">Select Team</option>
        {teams.map(item => (
          <option value={item.id}>{item.name}</option>
        ))}
      </select>
      <br />
      <br />
      <label for="team-select">2. Choose a player.</label>
      <br />
      <select
        name="team"
        id="team-select"
        onChange={e => setPlayer(e.target.value)}
      >
        <option value="">Select Player</option>
        {team &&
          roster.map(item => (
            <option value={item.id}>
              {item.name} ({item.position})
            </option>
          ))}
      </select>
      <br />
      <br />
      <label for="team-select">3. Create Question</label>
      <br />
      <textarea
        style={{ width: 500, height: 120 }}
        value={inputQuestion}
        onChange={e => setInputQuestion(e.target.value)}
      />
      <br />
      <br />
      <button
        style={{ marginBottom: 60 }}
        onClick={() => setTriggerQuestion(triggerQuestion + 1)}
      >
        Generate Question
      </button>
      <h2>{question && question.question}</h2>
      <br />
      <p>NOTES: </p>
      <p>Player stats include:</p>
      <code>
        assists, blocked, faceOffPct, gameWinningGoals, games, goals, hits,
        overTimeGoals, pim, plusMinus, points, powerPlayGoals, powerPlayPoints,
        shifts, shortHandedGoals, shortHandedPoints, shotPct, shots
      </code>
      {/*      <p>Goalie stats include:</p>
      <code>
        shutout,
        saves,
        powerPlaySaves,
        shortHandedSaves,
        evenSaves,
        powerPlayShots,
        shortHandedShots,
        evenShots,
        savePercentage
      </code>*/}
      <br />
      <br />
    </Layout>
  )
}

export default HockeyPlayerSampleQuestions
